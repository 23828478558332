const navItems = [
  {
    name: 'start',
    href: '#top',
    asScrollTo: true,
  },
  {
    name: 'kim jestem',
    href: '#about',
    asScrollTo: true,
  },
  {
    name: 'metoda',
    href: '#dmt',
    asScrollTo: true,
  },
  {
    name: 'oferta',
    href: '#offer',
    asScrollTo: true,
  },
  {
    name: 'aktualności',
    href: '#events',
    asScrollTo: true,
  },
  {
    name: 'cennik',
    href: '#pricing',
    asScrollTo: true,
  },
  {
    name: 'kontakt',
    href: '#contact',
    asScrollTo: true,
  },
];

export default navItems;
