export const theme = {
  white: '#F6F6F6',
  white2: '#ffffff',
  white3: '#FFF1EC',
  gray: '#E7E7E7',
  black: '#282727',
  green: '#87A083',
  orange: '#c2633e',
  blue: '#384294',

  headerHeight: '15rem',
  headerHeightScroll: '8rem',
};
